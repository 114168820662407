import * as React from "react"
import Layout from "../components/layout";
import {graphql, Link, useStaticQuery} from "gatsby";
import FollowUs from "../components/follow-us";

import {Helmet} from "react-helmet";

import noimage from "../images/noimage.png";
import {useEffect, useState} from "react";
import Footer from "../components/footer";
import Header from "../components/header";

const NewsPage = ({data}) => {

    const partners = data.allContentfulPartners.nodes;
    const teams = data.allContentfulTeam.nodes
    const pages = data.allContentfulPages.nodes

    const blogPosts = data.allContentfulBlogPost.nodes;
    const labels = data.allContentfulBlogPost.group;
    const [hash,setHash] = useState(false)

    useEffect(()=>{
        if (typeof window !== "undefined") {
            setHash(window.location.hash);
        }
    },[])

    function filter(slug){
        window.location.replace('/hirek/#'+slug)
        setTimeout(function(){
            window.location.reload()
        },200)
    }

    const slugify = text =>
        text
            .toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .trim()
            .replace(/\s+/g, '-')
            .replace(/[^\w-]+/g, '')
            .replace(/--+/g, '-')


    return (
        <Layout>
            <Helmet>
                <title>Hírek - Zempléni Hiúzok</title>
            </Helmet>
            <Header pages={pages} teams={teams}/>
            <main>
                <section className="section section-sm bg-gray-100">
                    <div className="container">
                        <div className="row row-30">
                            <div className="col-lg-8">
                                <article className="heading-component">
                                    <div className="heading-component-inner">
                                        <h5 className="heading-component-title">Hírek</h5>
                                    </div>
                                </article>

                                <div className="row">
                                    {blogPosts.filter(function (blogPost) {
                                            return (blogPost.label && '#'+slugify(blogPost.label[0]) === hash) || !hash;
                                        }).map(function (blogPost, index) {

                                            const image = blogPost.thumbnail ? blogPost.thumbnail.url : noimage;

                                            return <div className={'col-12 pb-4'}><div className="card border-0" key={index}>
                                                    <article className="post-future">
                                                        <div className={'row align-items-center'}>
                                                            <div className={'col-12 col-lg-6 h-100'}>
                                                                <Link to={`/hirek/${blogPost.slug}`} className="post-future-figure h-100">
                                                                    <img src={image} alt={blogPost.title}/>
                                                                </Link>
                                                            </div>
                                                            <div className={'col-12 col-lg-6'}>
                                                                <div className="post-future-main p-3">
                                                                    <h4 className="post-future-title">
                                                                        <Link to={`/hirek/${blogPost.slug}`}>{blogPost.title}</Link>
                                                                    </h4>
                                                                    <div className="post-future-meta mt-0">
                                                                        {blogPost.label && blogPost.label.map(function(label,labelIndex){
                                                                            return <div key={labelIndex} className="badge badge-primary">{label}</div>
                                                                        })}
                                                                        <div className="post-future-time">
                                                                            <span className="icon mdi mdi-clock"/>
                                                                            <time dateTime={blogPost.publishedAt}>{blogPost.publishedAt}</time>
                                                                        </div>
                                                                    </div>
                                                                    <hr/>
                                                                    <div className="post-future-text">
                                                                        <p>{blogPost.previewText}</p>
                                                                    </div>
                                                                    <div className="post-future-footer group-flex group-flex-x mb-0">
                                                                        <Link className="button button-gray-outline" to={`/hirek/${blogPost.slug}`}>
                                                                            Tovább
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </article>
                                                </div>
                                            </div>
                                        })}
                                </div>
                                <nav className="pagination-wrap d-none" aria-label="Page navigation">
                                    <ul className="pagination">
                                        <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                        <li className="page-item"><span className="page-link">...</span></li>
                                        <li className="page-item"><a className="page-link" href="#">14</a></li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="col-lg-4">
                                <div className="block-aside">
                                    <div className="block-aside-item">

                                        <div className="block-aside-item">
                                            <article className="heading-component">
                                                <div className="heading-component-inner">
                                                    <h5 className="heading-component-title">Kategóriák
                                                    </h5>
                                                </div>
                                            </article>

                                            <ul className="list-tags">
                                                <li className={`${!hash && 'active'}`}><Link onClick={()=>{filter('')}} to={`/hirek`}>Összes</Link></li>
                                                {labels.map(function(groupLabel,index){
                                                    return <li className={`${'#'+slugify(groupLabel.nodes[0].label) === hash && 'active'}`} key={index}><Link onClick={()=>{filter(slugify(groupLabel.nodes[0].label))}} to={`/hirek/#${slugify(groupLabel.nodes[0].label)}`}>{groupLabel.nodes[0].label}</Link></li>
                                                })}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="block-aside-item">
                                        <FollowUs/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer partners={partners}/>
        </Layout>
    )
}

export default NewsPage

export const query = graphql`
  query getNews {
      allContentfulBlogPost(sort: {fields: publishedAt, order: DESC}) {
        nodes {
          label
          slug
          title
          text {
            raw
          }
          publishedAt
          previewText
          thumbnail {
            url
          }
        }
        group(field: label) {
          nodes {
            label
          }
        }
      }
      allContentfulPartners {
        nodes {
          title
          logo {
            url
          }
        }
      }
      allContentfulPages {
        nodes {
          text {
            raw
          }
          title
          slug
        }
      }
      allContentfulTeam(sort: {fields: title, order: ASC}) {
        nodes {
          title
          label
        }
      }
    }
`
